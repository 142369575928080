import React from 'react';
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown/with-html"
import $ from 'jquery'

/**
 * Assets
 */

import './styles/_index.scss';

/**
 * Components
 */
import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import StaticSearch from '@blocks/forms/static-search';
import AreaSearch from '@blocks/forms/area-search';

const StaticIntro = (props) => {
	if (typeof window !== "undefined") {

    $.getJSON('https://www.theestas.com/api/widget?api_token=qayro8ImsRiW2xXhxJoabXNVgLYnOG5flqiJ34WhdLQ7WtXOg1rs1Cos6B8E&with_comments=true&demo=0', function(data) {
        var text = data.reviewCount;    
        var rating = data.rating+'%';    
        $(".mypanel").html(text);
        $(".myrating").html(rating);
    })
	}
    const data = useStaticQuery(graphql`
        query {
            reviews: file(relativePath: { eq: "reviews.png" }) {
            childImageSharp {
                fluid(maxWidth: 100, maxHeight: 44, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);
    return (
        <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"  className="static-intro">
            <Intro>
                <Breadcrumbs detailTitle={props.Title} alias={props.alias}/>
                <div className="intro__text">
                <h1>{props.Title}</h1>    
                {props.Intro !=null &&
                    <ReactMarkdown source={props.Intro} escapeHtml={false}/>
                }
                </div>
                {props.search == true &&
                <StaticSearch />
                }
                {props.search == "area" &&
                <AreaSearch />
                }
                {props.review == true &&
                <div className="intro__reviews">
                    <Img fluid={data.reviews.childImageSharp.fluid} alt="Estats Logo - Anthony Pepe" />
                    <p><span className='myrating'></span> <span className="d-none d-md-inline">Customer Service Rating</span> from <strong><span className='mypanel'></span> Reviews</strong></p>
                </div>
                }
            </Intro>
        </div>
    )
}

export default StaticIntro
